<template>
  <el-dialog :visible="visible" class="media-dialog dialog-vertical" width="940px" :show-close="false"
    :close-on-press-escape="false" append-to-body @close="close" close-on-click-modal>
    <template #title>
      <div class="media-dialog-title flex-between">
        <div class="el-dialog__title" :data-media-key="mediaKey">选择{{typeText}}</div>
        <div class="flex" style="height: 36px;">
          <el-form v-if="tab.tabName === 'default'" size="medium" inline @submit.native.prevent>
            <el-form-item v-if="type.length > 1 && type.includes('image') && type.includes('video')">
              <el-select v-model="queryType" @change="handleFilterType">
                <el-option label="图片、视频" value="" />
                <el-option v-for="t in type" :key="t" :label="handleTypeOptionLabel(t)" :value="t" />
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-select v-model="query.pid" @change="doFilter('page', 1)">
                <el-option v-for="(folder) in folders" :key="folder.id" :label="folder.title"
                  :value="folder.id" />
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-input v-model="query.keyword" placeholder="请输入"
                @keyup.enter.native="doFilter('page', 1)">
                <i @click="doFilter('page', 1)" slot="suffix"
                  class="el-input__icon el-icon-search cursor-pointer" />
              </el-input>
            </el-form-item>
          </el-form>
          <button class="el-dialog__headerbtn" @click="close">
            <i class="el-icon-close" />
          </button>
        </div>
      </div>
      <list-tabs v-if="tab.tabs && tab.tabs.length" v-model="tab.tabName" :tabs="tab.tabs"
        @tab-click="handleTabClick" />
      <!-- 全选按钮 -->
      <div v-if="multiple && ['default', 'library'].includes(tab.tabName)" class="absolute-btn">
        <el-checkbox v-model="allChecked" :true-label="1" :false-label="0">全选</el-checkbox>
      </div>
    </template>
    <!-- 列表 -->
    <media-list-pane class="media-tab-content list" v-show="tab.tabName === 'default'" v-model="checkedList"
      :loading="loading" :list="list" :query="query" :on-query="onQuery"
      :info="info" />
    <!-- 手机上传 -->
    <media-phone-upload-pane class="media-tab-content" v-if="showPhoneUpload" v-show="tab.tabName === 'phone'"
      :code="code" :loading="loading" :refresh="isRefreshCode" :on-refresh="getCode" />
    <!-- 本地上传 -->
    <media-local-upload-pane ref="localUpload" class="media-tab-content" v-show="tab.tabName === 'local'" :folders="folders"
      :type="type" :typeText="typeText" :on-upload="onUpload" :on-complete="onUploadComplete" @cancel="close" />
    <!-- 外部链接 -->
    <!--    <media-external-link-pane class="media-tab-content"-->
    <!--                              v-model="config.external_link"-->
    <!--                              v-show="tab.tabName === 'external'"-->
    <!--    ></media-external-link-pane>-->
    <!-- 直播链接 -->
    <media-live-link-pane class="media-tab-content" v-model="config.url" v-show="tab.tabName === 'live'" />
    <!-- 素材中心 -->
    <media-photo-library-pane v-show="tab.tabName === 'library' && type.includes('image')"
                              :active="tab.tabName === 'library'"
                              class="media-tab-content" v-model="checkedList" @refresh="handleSwitchPanel"
                              :on-query-free="onQueryFree" :info="info" @list-info="free_list = $event" />
    <template #footer v-if="tab.tabName !== 'local'">
      <el-button size="small" @click="close">取消</el-button>
      <el-button v-if="type.length === 1 && type.includes('image')" size="small" :disabled="!checkedList.length" @click="clickCut" type="success">裁剪</el-button>
      <el-button size="small" :disabled="!checkedList.length" type="primary" @click="confirm">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import MediaListPane from "./MediaListPane";
import MediaPhoneUploadPane from "./MediaPhoneUploadPane";
import MediaLocalUploadPane from "./MediaLocalUploadPane";
import MediaExternalLinkPane from "./MediaExternalLinkPane";
import MediaLiveLinkPane from "./MediaLiveLinkPane";
import MediaPhotoLibraryPane from "./MediaPhotoLibraryPane";
import {isArray,} from '../../utils/tool'

export default {
  name: "BaseMediaDialog",
  inject: ['mediaKey'],
  provide () {
    return {
      uploadUrl: this.uploadUrl,
      // 使用 get() 才能实时监听到变化
      getMax: () => this.max,
      getRatio: () => this.ratio,
      getRatioList: () => this.ratioList,
      multiple: this.multiple,
    }
  },
  components: {
    MediaPhotoLibraryPane,
    MediaLiveLinkPane,
    MediaExternalLinkPane,
    MediaLocalUploadPane,
    MediaPhoneUploadPane,
    MediaListPane,
  },
  props: {
    uploadUrl: String,
    // 打开/关闭弹窗
    visible: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    // 接口返回的数据
    list: {
      type: Object,
    },
    folders: {
      type: Array,
    },
    code: {
      type: String,
    },
    // 默认单选
    multiple: {
      type: Boolean,
      default: false,
    },
    // 仅多选时表示剩余可选数量
    max: {
      type: Number,
      default: 99,
    },
    ratio: [String, Array], // 5:4
    // folder, file, image, video, audio
    type: {
      type: Array,
      default: () => ["image"],
    },
    onQuery: {
      type: Function,
    },
    onRefreshCode: {
      type: Function,
    },
    onUpload: {
      type: Function,
      required: true,
    },
    onUploaded: Function,
    showPhoneUpload: {
      type: Boolean,
      default: false,
    },
    // 当type只有video时生效
    showLiveLink: {
      type: Boolean,
      default: true,
    },
    onQueryFree: Function
  },
  data() {
    return {
      query: {
        page: 1,
        page_size: 10,
        keyword: "",
        pid: -1,
        type: ["image"],
      },
      tab: {
        tabName: "",
        tabs: [],
      },
      allChecked: 0,
      checkedList: [],
      lang: {
        typeIndex: 0,
        map: {
          type: ["image", "video", "audio", "file", "folder", "mix"],
          text: ["图片", "视频", "音频", "文件", "文件夹", "文件"],
          size: ["张", "个", "个", "个", "个", "个"],
        },
      },
      isRefreshCode: false,
      // 直播和外部链接
      config: {
        title: "",
        // 外部链接
        // external_link: "",
        // 直播
        url: "",
        // url: "https://test-streams.mux.dev/x36xhzz/x36xhzz.m3u8",
        // url: "https://api.dogecloud.com/player/get.m3u8?vcode=5ac682e6f8231991&userId=17&ext=.m3u8"
        // url: "http://kbs-dokdo.gscdn.com/dokdo_300/_definst_/dokdo_300.stream/playlist.m3u8"
      },
      free_list: {
        data: []
      },
      queryType: ''
    };
  },
  computed: {
    typeText: function () {
      return this.lang.map.text[this.lang.typeIndex];
    },
    info: function () {
      let i = this.lang.typeIndex;
      return `最多可选${this.max}${this.lang.map.size[i]}${this.typeText}`;
    },
    lastAudioIdString: function () {
      return this.$store.getters.lastAudioId;
    },
    ratioList() {
      if (this.ratio && typeof this.ratio === 'string') {
        return [this.ratio]
      }
      return this.ratio || []
    }
  },
  watch: {
    visible(val) {
      // 弹窗显示
      if (val) {
        // 清空勾选项；
        this.checkedList = [];

        if (this.$refs['localUpload']) this.$refs['localUpload'].reset()

        if (this.query.page === 1) {
          // 原地刷新，如果弹窗打开时并不在第一页，就不处理
          this.doFilter('page', 1); // 刷新
        } else if (this.queryType && this.type.includes(this.queryType)) {
          if (!this.list?.data?.length) {
            this.doFilter('page', 1);
          }
        } else if (JSON.stringify(this.type) !== JSON.stringify(this.query.type)) {
          // 切换了媒体类型(有共用一个弹窗，切换图片和视频的需求)
          this.query.type = this.type;
          this.doFilter('page', 1);
        } else if (!this.list?.data?.length) {
          this.doFilter('page', 1);
        }
      } else {
        // 弹窗关闭
      }
    },
    code() {
      this.isRefreshCode = false;
    },
    allChecked(val) {
      let list = []
      if (this.tab.tabName === 'default') {
        list = this.list.data
      } else if (this.tab.tabName === 'library') {
        list = this.free_list.data
      }
      if (!val) {
        if (this.checkedList.length === list.length) {
          this.checkedList = []
        }
      } else {
        /* 全选 */
        this.checkedList = list
        if (this.checkedList.length > this.max) {
          return this.$message.info(this.info);
        }
      }
    },
    /* TODO: 两个列表的全选互斥，需要清空 */
    // 'tab.tabName' (val, oldVal) {
      // if ((oldVal === 'library' && val === 'default') || (oldVal === 'default' && val === 'library')) {
        // this.checkedList = []
      // }
    // },
    query: {
      handler() {
        this.checkedList = []
      },
      deep: true
    },
    'checkedList.length'(val, oldVal) {
      const list = this.tab.tabName === 'default' ? (this.list.data || []) : this.free_list.data
      const listLength = list.length || 0
      if (val === oldVal + 1 && val !== listLength && val > this.max) {
        this.$message.info(this.info);
      }
      if (val < oldVal) {
        if (this.allChecked) this.allChecked = 0
      } else if (val === listLength) {
        /* 素材中心和媒体库支持跨选 */
        if (this.onQueryFree === undefined) {
          if (!this.allChecked) this.allChecked = 1
        }
      }
    }
  },
  created() {
    this.init();
    this.registerEvent()
  },
  methods: {
    handleFilterType() {
      switch(this.queryType) {
        case 'image':
        case 'video':
        case 'audio':
        case 'file':
          this.query.type = [this.queryType]
          break
        default:
          this.query.type = this.type
      }
      this.doFilter('page', 1)
    },
    handleTypeOptionLabel(t) {
      switch (t) {
        case 'image':
          return "图片"
        case 'video':
          return '视频'
        case 'audio':
          return '音频'
        case 'file':
          return '其他'
      }
    },
    /**
     * 从素材中心裁剪完自动切换到媒体库第一页
     */
    handleSwitchPanel() {
      this.query.page = 1
      this.onQuery(this.query)
      this.tab.tabName = 'default'
    },
    init() {
      this.tab.tabs = [
        { id: 0, label: "媒体库", name: "default" },
        { id: 1, label: "本地上传", name: "local" },
      ];
      if (this.showPhoneUpload) {
        this.tab.tabs.push({ id: 2, label: "手机上传", name: "phone" });
      }
      this.tab.tabName =
        this.tab.tabs && this.tab.tabs.length
          ? this.tab.tabs[0].name
          : "default";

      if (this.type.length > 1) {
        // 混合类型上传
        this.lang.typeIndex = 5;
      } else {
        let index = this.lang.map.type.findIndex(
          (item) => item === this.type[0]
        );
        this.lang.typeIndex = index > -1 ? index : 0;
      }

      // 仅在初始化时
      this.query.type = this.type;

      if (
        this.showLiveLink &&
        this.query.type.length === 1 &&
        this.query.type[0] === "video"
      ) {
        //   this.tab.tabs.push({id: 3, label: '外部链接', name: 'external'});
        this.tab.tabs.push({ id: 4, label: "直播链接", name: "live" });
      }
      if (this.onQueryFree !== undefined && this.type.includes('image')) {
        this.tab.tabs.push({ id: 5, label: "素材中心", name: "library"});
      }
    },
    registerEvent() {
      // 来自裁剪弹窗的消息
      this.$bus.on('confirm-media-dialog', data => {
        // 一个选图入口对应一个key
        if (data.mediaKey !== this.mediaKey) return

        // console.log(data, this.mediaKey)
        data = data.data

        this.checkedList = []
        if (isArray(data)) {
          // this.list.data.forEach(ld => {
          //   data.forEach(d => {
          //     const target = ld.id === d.id
          //     if (target) this.checkedList.push(ld)
          //    })
          // })
          this.checkedList.push(...data)
        } else {
          // this.checkedList.push(this.list.data.find(li => li.id === data.id))
          this.checkedList.push(data)
        }

        this.confirm()
      });
    },
    getCode() {
      this.onRefreshCode();
    },
    confirm() {
      if (this.tab.tabName === "live") {
        if (!this.config.url) return this.$message.error("请填写直播链接");
        this.$emit("confirm", [
          {
            url: this.config.url,
            create_time: "",
            file_size: "",
            id: 0,
            pid: 0,
            title: "",
            type: "video",
            config: null,
          },
        ]);
      } else if (this.tab.tabName === "external") {
        this.$emit("confirm", [{ external_link: this.config.external_link }]);
      } else {
        if (!this.checkedList.length) {
          return this.$message.info(`请选择${this.typeText}`);
        }
        if (this.multiple) {
          if (this.checkedList.length > this.max) {
            // console.log('多选超了', this.checkedList.length)
            if (!this.$store.state.crop.directly) return this.$message.info(this.info);
          }
        }
        this.$emit("confirm", this.checkedList);
      }
      this.close();
    },
    close() {
      this.$emit("close");
      if (this.query.type === "audio") {
        // 弹窗关闭时暂停上一个正在播放的音频
        let lastPlaying = this.lastAudioIdString;
        let ctx = document.querySelector("#" + lastPlaying);
        if (ctx && !ctx.paused) {
          ctx.pause();
        }
        this.$store.commit("setLastAudioId", "");
      }
    },
    clickCut() {
      // TODO: 弹窗裁剪目前图片（数组）
      // console.log(this.checkedList) // [{url}]
      this.$store.dispatch('crop/openMediaCropDialog', {
        imgSrc: this.checkedList,
        ratio: this.ratioList[0],
        ratioList: this.ratioList,
        mediaKey: this.mediaKey
      })
    },
    doFilter(key, value) {
      if (key) {
        this.query[key] = value;
      }
      this.onQuery(this.query);
    },
    // 刷新数据（列表/二维码）
    handleTabClick(e) {
      if (e.paneName === "phone") {
        this.isRefreshCode = true;
      } else if (e.paneName === "default") {
        // this.doFilter()
      }
    },
    /* 上传完成后 */
    onUploadComplete(arr) {
      if (this.onUploaded !== undefined) {
        this.onUploaded(arr)
        this.close()
      } else {
        this.tab.tabName = "default";

        this.query.type = this.type;

        this.doFilter("page", 1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.el-dialog {
  .el-dialog__header {
    padding-bottom: $space;
  }
}

::v-deep .el-dialog {
  .el-dialog__header {
    padding-bottom: $space;
    position: relative;

    .absolute-btn {
      position: absolute;
      right: 20px;
      top: 97px;
    }
  }
}

.media-dialog-title {
  padding-right: 67px;

  .el-dialog__title {
    /*padding: 16px 0;*/
  }
}

.el-form--inline .el-form-item {
  margin-bottom: 0;
}

.table-tab {
  /*margin-top: 0;*/
  margin-bottom: 0;
}

.el-select,
.el-input {
  width: 160px;
}

.el-select + .el-select,
.el-select + .el-input {
  margin-left: $space;
}

.media-tab-content {
  min-width: 688px;
  /* 列表高度加上页码高度 */
  height: calc(348px + 71px);

  &.list {
    min-height: calc(348px + 71px);
    height: unset;
  }

  &.media-local-upload-pane {
    height: calc(348px + 71px + 52px);
  }
}
</style>
